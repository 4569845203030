import React from "react";
import "./product.scss";
const Product = () => {
  return (
    <>
      <section className="mainbanner1" id="product">
        <div className="mainparent1">
          <div className="parent1">
            <p className="para1">Welcome to Prodex section</p>
            <p className="para2">////</p>
          </div>
          <div className="parent111">
            <div
              className="parent11 animate__animated animate__backInLeft"
              style={{ animationDelay: "0.5s" }}
            >
              <img src="\assets\logpic.svg" />
              <h1 className="para3 ">
              Decentralized E-Commerce, Driven by Community Power
              </h1>
              <p className="para4">
              Prodex is a decentralized e-commerce platform where the community drives growth by
building and managing a global product catalog. Contributors ensure transparency, earn
rewards, and share in the platform’s success. Sellers can list stores, reach buyers
worldwide, and manage their business effortlessly. Join us to shape the future of
e-commerce together!
              </p>
              <a href="/Prodex Whitepaper.pdf" target="_blank">
                <button className="para5">
                  Read Whitepaper{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      d="M2.00049 9.38152L12.2931 9.38152L8.64675 13.0279L9.35371 13.7349L14.2071 8.88154L9.35371 4.02821L8.64675 4.73522L12.2931 8.38156L2.00049 8.38156L2.00049 9.38152Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </a>
            </div>
            <div className="img1divv animate__animated animate__backInRight">
              <img className="img-fluid" src="\assets\part1.svg" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;
