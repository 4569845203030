import "../../App.scss";
import React, { useEffect } from "react";
import Header from "./Header/Header";
import Banner from "./Banner/Banner";
import WOW from "wowjs";
import Footer from "./Footer/Footer";
import Product from "./Product/Product";
import Vision from "./Vision/Vision";
import Mission from "./Mission/Mission";
import Features from "./Features/Features";
import Tokenomics from "./Tokenomics/Tokenomics";
import Roadmap from "./Roadmap/Roadmap";
import Blog from "./Blog/Blog";
import Faqs from "./Faqs/Faqs";
import Community from "./Community/Community";

function Landing() {
  useEffect(() => {
    const wow = new WOW.WOW({
      offset: 200,
      mobile: true,
      live: true,
    });
    wow.init();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="mainlandang">
        <Header />
        <Banner />
        <Product />
        <Vision />
        <Mission />
        <Features />
        <Tokenomics />
        <Roadmap />
        {/* <Blog /> */}
        <Faqs />
        <Community />
        <Footer />
      </div>
    </>
  );
}

export default Landing;
