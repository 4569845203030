import React, { useState } from "react";
import "./faqs.scss";
import Accordion from "react-bootstrap/Accordion";

const Faqs = () => {
  const [activeKey, setActiveKey] = useState("0");
  const handleSelect = (key) => {
    setActiveKey(key);
  };

  return (
    <>
      <section className="mainbanner8" id="faqs">
        <div className="mainparent8">
          <div className="parent8">
            <p className="para1">FAQs</p>
            <p className="para2">////</p>
          </div>
          <div className="parentbar111">
            <h1 className="para444444 animate__animated animate__heartBeat">
              frequently asked questions
            </h1>
            <div className="mainfaqs">
              <div className="innerfaq">
                <Accordion activeKey={activeKey} onSelect={handleSelect}>
                  <Accordion.Item
                    eventKey="0"
                    className={activeKey === "0" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>Q01.</span>
                        What is Prodex?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="para">
                        Prodex is a decentralized e-commerce platform where the
                        community creates and manages a global product catalog.
                        It empowers sellers to list their stores, buyers to find
                        verified product details, and contributors to earn
                        rewards for maintaining accurate product data.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="1"
                    className={activeKey === "1" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>Q02. </span>
                        How does the community contribute to Prodex?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="para">
                        The community drives the platform by adding, editing,
                        and verifying product listings. Contributors earn token
                        rewards and share in the revenue generated from
                        transactions tied to the product pages they manage.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="2"
                    className={activeKey === "2" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>Q03.</span>
                        How does Prodex ensure transparency?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="para">
                        Prodex uses blockchain technology to guarantee
                        transparent pricing, verified product details, and
                        secure transactions. Contributors and sellers are
                        rewarded fairly, and buyers enjoy a trustworthy shopping
                        experience.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="3"
                    className={activeKey === "3" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>Q04.</span>How can sellers use Prodex?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="para">
                        Sellers can create stores, link their products to
                        verified product pages, and manage listings efficiently.
                        They can also set dynamic pricing, shipping options, and
                        promotional discounts to reach buyers globally.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="4"
                    className={activeKey === "4" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>Q05.</span>What makes Prodex different from
                        traditional e-commerce platforms?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="para">
                        Prodex combines a community-driven product catalog with
                        e-commerce functionality, ensuring transparency,
                        fairness, and shared ownership. Unlike traditional
                        platforms, Prodex rewards contributors and sellers for
                        their efforts, creating a collaborative ecosystem.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;
