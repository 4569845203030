import React, { useState } from "react";
import "./header.scss";
import { Offcanvas } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;

  const handleClick1 = (e) => {
    setShow(false);
    setShow1(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = "#home";
    }, 500);
  };
  const handleClick2 = (e) => {
    setShow(false);
    setShow1(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = "#about";
    }, 500);
  };
  const handleClick3 = (e) => {
    setShow(false);
    setShow1(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = "#vision";
    }, 500);
  };
  const handleClick4 = (e) => {
    setShow(false);
    setShow1(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = "#mission";
    }, 500);
  };
  const handleClick5 = (e) => {
    setShow(false);
    setShow1(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = "#features";
    }, 500);
  };
  const handleClick6 = (e) => {
    setShow(false);
    setShow1(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = "#tokenomics";
    }, 500);
  };
  const handleClick7 = (e) => {
    setShow(false);
    setShow1(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = "#roadmap";
    }, 500);
  };
  const handleClick8 = (e) => {
    setShow(false);
    setShow1(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = "#blog";
    }, 500);
  };
  const handleClick9 = (e) => {
    setShow(false);
    setShow1(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = "#faqs";
    }, 500);
  };
  return (
    <>
      <section className="main-navbar">
        <nav class="navbar navbar-expand-xl">
          <a class="navbar-brand" href="#">
            <img src="\logo.svg" alt="logoimg" className="logoimg" />
          </a>
          <ul class="navbar-nav">
            <li>
            {/* <HashLink to="" class="nav-link" style={
                    isActive("#home")
                      ? {
                        // color: "#31F2B3",
                      }
                      : {}
                  }> <a>About us</a></HashLink>
            </li>
            <li> */}
            <HashLink to="#vision" class="nav-link" style={
                    isActive("#vision")
                      ? {
                        // color: "#31F2B3",
                      }
                      : {}
                  }>
              <a href="">Vision</a></HashLink>
            </li>
            <li>
            <HashLink to="#mission" class="nav-link" style={
                    isActive("#mission")
                      ? {
                        // color: "#31F2B3",
                      }
                      : {}
                  }>
              <a href="">Mission</a></HashLink>
            </li>
            <li>
            <HashLink to="#features" class="nav-link" style={
                    isActive("#features")
                      ? {
                        // color: "#31F2B3",
                      }
                      : {}
                  }>
              <a href="">Features</a></HashLink>
            </li>
            <li>
            <HashLink to="#tokenomics" class="nav-link" style={
                    isActive("#tokenomics")
                      ? {
                        // color: "#31F2B3",
                      }
                      : {}
                  }>
              <a href="">Tokenomics</a></HashLink>
            </li>
            <li>
            <HashLink to="#roadmap" class="nav-link" style={
                    isActive("#roadmap")
                      ? {
                        // color: "#31F2B3",
                      }
                      : {}
                  }>
              <a href="">Roadmap</a></HashLink>
            </li>
            <li>
            <HashLink to="#blog" class="nav-link" style={
                    isActive("#blog")
                      ? {
                        // color: "#31F2B3",
                      }
                      : {}
                  }>
              <a href="">Blog</a></HashLink>
            </li>
            <li>
            <HashLink to="#faqs" class="nav-link" style={
                    isActive("#faqs")
                      ? {
                        // color: "#31F2B3",
                      }
                      : {}
                  }>
              <a href="">FAQs</a></HashLink>
            </li>
          </ul>
          <div className="mblbtn d-none">
            <button className="btnss">
              <img
                src="\assets\mblmenu.png"
                alt="img"
                className="img-fluid"
                onClick={handleShow1}
              />
            </button>
          </div>
        </nav>
      </section>

      <Offcanvas className="mblmenu" show={show1} onHide={handleClose1}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img
              src="\assets\mblmenu1.svg"
              alt="img"
              className="img-fluid"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="maintext">
            <HashLink onClick={handleClick1} to="#home" className="para">
              Home
            </HashLink>
            {/* <HashLink onClick={handleClick2} to="#about" className="para">
              About Us
            </HashLink> */}
            <HashLink onClick={handleClick3} to="#vision" className="para">
              Vision
            </HashLink>
            <HashLink onClick={handleClick4} to="#mission" className="para">
              Mission
            </HashLink>
            <HashLink onClick={handleClick5} to="#features" className="para">
              Features
            </HashLink>
            <HashLink onClick={handleClick6} to="#tokenomics" className="para">
              Tokenomics
            </HashLink>
            <HashLink onClick={handleClick7} to="#roadmap" className="para">
              Roadmap
            </HashLink>
            <HashLink onClick={handleClick8} to="#blog" className="para">
              Blog
            </HashLink>
            <HashLink onClick={handleClick9} to="#faqs" className="para">
              FAQs
            </HashLink>
          </div>
          <div className="innerfooter">
            <div className="socialsmain">
              <div className="socialinner">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M16.7236 2.0625H19.7559L13.1312 9.63417L20.9247 19.9375H14.8224L10.0429 13.6886L4.57408 19.9375H1.53991L8.62575 11.8387L1.14941 2.0625H7.40658L11.7268 7.77425L16.7236 2.0625ZM15.6593 18.1225H17.3396L6.49358 3.78217H4.6905L15.6593 18.1225Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="socialinner">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <g clip-path="url(#clip0_103_51239)">
                    <path
                      d="M15.125 0H6.875C5.05164 0 3.30295 0.724328 2.01364 2.01364C0.724328 3.30295 0 5.05164 0 6.875L0 15.125C0 16.9484 0.724328 18.697 2.01364 19.9864C3.30295 21.2757 5.05164 22 6.875 22H15.125C16.9484 22 18.697 21.2757 19.9864 19.9864C21.2757 18.697 22 16.9484 22 15.125V6.875C22 5.05164 21.2757 3.30295 19.9864 2.01364C18.697 0.724328 16.9484 0 15.125 0ZM19.9375 15.125C19.9375 17.7787 17.7787 19.9375 15.125 19.9375H6.875C4.22125 19.9375 2.0625 17.7787 2.0625 15.125V6.875C2.0625 4.22125 4.22125 2.0625 6.875 2.0625H15.125C17.7787 2.0625 19.9375 4.22125 19.9375 6.875V15.125Z"
                      fill="black"
                    />
                    <path
                      d="M11 5.5C9.54131 5.5 8.14236 6.07946 7.11091 7.11091C6.07946 8.14236 5.5 9.54131 5.5 11C5.5 12.4587 6.07946 13.8576 7.11091 14.8891C8.14236 15.9205 9.54131 16.5 11 16.5C12.4587 16.5 13.8576 15.9205 14.8891 14.8891C15.9205 13.8576 16.5 12.4587 16.5 11C16.5 9.54131 15.9205 8.14236 14.8891 7.11091C13.8576 6.07946 12.4587 5.5 11 5.5ZM11 14.4375C10.0887 14.4364 9.21495 14.0739 8.57053 13.4295C7.92611 12.7851 7.56359 11.9113 7.5625 11C7.5625 9.10388 9.10525 7.5625 11 7.5625C12.8948 7.5625 14.4375 9.10388 14.4375 11C14.4375 12.8948 12.8948 14.4375 11 14.4375Z"
                      fill="black"
                    />
                    <path
                      d="M16.9126 5.82049C17.3173 5.82049 17.6454 5.49237 17.6454 5.08761C17.6454 4.68286 17.3173 4.35474 16.9126 4.35474C16.5078 4.35474 16.1797 4.68286 16.1797 5.08761C16.1797 5.49237 16.5078 5.82049 16.9126 5.82049Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_103_51239">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="socialinner">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <g clip-path="url(#clip0_103_51243)">
                    <path
                      d="M0.335938 21.9111H6.03235V6.93164H0.335938V21.9111ZM1.625 8.2207H4.74329V20.622H1.625V8.2207Z"
                      fill="black"
                    />
                    <path
                      d="M3.18394 0.088623C1.42828 0.088623 0 1.51695 0 3.27256C0 5.02818 1.42828 6.45655 3.18394 6.45655C4.9396 6.45655 6.36792 5.02822 6.36792 3.27256C6.36792 1.5169 4.93956 0.088623 3.18394 0.088623ZM1.28906 3.27256C1.28906 2.22774 2.13907 1.37769 3.18394 1.37769C4.22881 1.37769 5.07886 2.22769 5.07886 3.27256C5.07886 4.31743 4.22881 5.16749 3.18394 5.16749C2.13907 5.16749 1.28906 4.31739 1.28906 3.27256Z"
                      fill="black"
                    />
                    <path
                      d="M21.9861 11.6353C21.7833 8.71969 19.3513 6.4729 16.3361 6.4729C14.9875 6.4729 13.7144 6.94178 12.7113 7.77374V6.93155H7.25293V21.911H12.9494V13.7184C12.9494 12.7951 13.7005 12.0439 14.6239 12.0439C15.5471 12.0439 16.2983 12.7951 16.2983 13.7188L16.3038 21.9111H21.9998V11.6492L21.9861 11.6353ZM11.6603 20.622H8.54199V8.22065H11.4221V10.0617L12.4536 10.0677L12.6452 9.77015C13.4552 8.51267 14.835 7.76201 16.3361 7.76201C18.7353 7.76201 20.6563 9.59256 20.7095 11.9293L20.7108 20.622H17.592L17.5874 13.7184C17.5874 12.0843 16.2579 10.7549 14.6239 10.7549C12.9898 10.7549 11.6603 12.0843 11.6603 13.7184V20.622Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_103_51243">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="socialinner">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <g clip-path="url(#clip0_103_51248)">
                    <path
                      d="M14.6909 10.2424L9.10476 7.06265C8.83886 6.91122 8.51233 6.91277 8.24768 7.06656C7.98307 7.22044 7.82025 7.50344 7.82025 7.80951V14.1691C7.82025 14.4752 7.98307 14.7582 8.24768 14.9121C8.51233 15.0659 8.83882 15.0674 9.10476 14.916L14.6909 11.7362C15.2665 11.4148 15.2664 10.5637 14.6909 10.2424ZM9.53902 12.6911V9.28761L12.5285 10.9894L9.53902 12.6911ZM21.6698 6.42364C21.3903 4.45053 19.8089 2.93677 17.8239 2.74246C16.256 2.58897 13.7993 2.40601 11 2.40601C8.20071 2.40601 5.74406 2.58897 4.17608 2.74246C2.19111 2.93673 0.609673 4.45053 0.330237 6.4236C-0.110079 9.54882 -0.110079 12.4513 0.330237 15.5765C0.609673 17.5496 2.19111 19.0633 4.17608 19.2577C5.74402 19.4111 8.20071 19.5941 11 19.5941C13.7993 19.5941 16.256 19.4111 17.8239 19.2577C19.8089 19.0634 21.3903 17.5496 21.6698 15.5765C22.1101 12.4513 22.1101 9.54882 21.6698 6.42364ZM19.9679 15.3355C19.8001 16.5208 18.8496 17.4302 17.6565 17.547C16.1266 17.6968 13.7299 17.8753 11 17.8753C8.27011 17.8753 5.8734 17.6968 4.34353 17.547C3.15042 17.4302 2.19996 16.5208 2.03212 15.3355C1.82397 13.8658 1.71843 12.4072 1.71843 11.0001C1.71843 9.59295 1.82397 8.13437 2.03212 6.66457C2.19996 5.47928 3.15042 4.5699 4.34353 4.45311C5.87344 4.30331 8.27011 4.12477 11 4.12477C13.7299 4.12477 16.1266 4.30327 17.6565 4.45311C18.8496 4.5699 19.8001 5.47928 19.9679 6.66462C20.3857 9.63034 20.3857 12.3698 19.9679 15.3355Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_103_51248">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="socialinner">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <g clip-path="url(#clip0_103_51250)">
                    <path
                      d="M21.8054 3.40156C21.9931 3.21855 22.0513 2.94007 21.9525 2.69713C21.8538 2.45427 21.6177 2.29541 21.3555 2.29541H14.9127C14.5568 2.29541 14.2682 2.58399 14.2682 2.93994V6.04005L11.8562 12.293L7.43255 2.67074C7.3274 2.44202 7.09868 2.29545 6.84693 2.29545H0.812826C0.563908 2.29545 0.337204 2.43884 0.230513 2.66374C0.123822 2.88864 0.156177 3.15491 0.313657 3.34772L3.0414 6.68729V14.9372L0.136239 18.6635C-0.0151824 18.8578 -0.0427254 19.1213 0.065384 19.3427C0.173493 19.564 0.398263 19.7044 0.64456 19.7044H6.72735C6.97364 19.7044 7.19841 19.564 7.30652 19.3427C7.41463 19.1214 7.38709 18.8578 7.23567 18.6635L4.33051 14.9372V9.20737L9.35948 19.3462C9.46867 19.5663 9.69283 19.7043 9.93677 19.7043C9.94669 19.7043 9.95666 19.7041 9.96663 19.7036C10.222 19.6919 10.4462 19.5303 10.5382 19.2917L14.2682 9.62197V16.4396L12.5344 18.6635C12.383 18.8578 12.3555 19.1213 12.4636 19.3427C12.5717 19.564 12.7964 19.7043 13.0428 19.7043H21.0902C21.3365 19.7043 21.5612 19.564 21.6693 19.3426C21.7775 19.1213 21.7499 18.8578 21.5985 18.6635L19.8647 16.4396V5.293L21.8054 3.40156ZM1.9643 18.4153L3.68593 16.207L5.40756 18.4153H1.9643ZM9.86303 17.4604L4.26335 6.17111C4.26214 6.16861 4.26051 6.16638 4.25926 6.16393C4.2517 6.14919 4.24345 6.13493 4.23486 6.12088C4.23142 6.11525 4.22824 6.10949 4.22463 6.10399C4.21397 6.08779 4.2025 6.07219 4.19051 6.05702C4.18862 6.05466 4.18708 6.05208 4.18519 6.04972L4.18338 6.04753C4.18338 6.04753 4.18334 6.04744 4.18329 6.04744L2.17154 3.58447H6.43391L11.209 13.9711L9.86303 17.4604ZM18.7118 17.0575L19.7704 18.4153H14.3625L15.421 17.0575C15.5093 16.9442 15.5573 16.8048 15.5573 16.6612V3.58452H19.7707L18.7702 4.55956C18.6458 4.68086 18.5756 4.84733 18.5756 5.02113V16.6612C18.5756 16.8048 18.6235 16.9443 18.7118 17.0575Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_103_51250">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="socialinner">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="25"
                  viewBox="0 0 26 25"
                  fill="none"
                >
                  <path
                    d="M21.5878 4.16668C21.5297 4.16587 21.4721 4.17723 21.4186 4.20001L3.91864 11.7C3.84286 11.7325 3.7784 11.7867 3.73344 11.8558C3.68848 11.9249 3.66502 12.0058 3.66605 12.0882C3.66707 12.1707 3.69253 12.2509 3.7392 12.3189C3.78587 12.3869 3.85166 12.4394 3.92823 12.47L7.91531 14.065L9.52073 18.8821C9.54322 18.9495 9.58257 19.01 9.63502 19.0578C9.68747 19.1057 9.7513 19.1394 9.82043 19.1557C9.88956 19.172 9.96171 19.1703 10.03 19.1509C10.0983 19.1314 10.1605 19.0948 10.2107 19.0446L12.9357 16.32L17.9753 20.73C18.03 20.7779 18.0963 20.8106 18.1676 20.8249C18.2388 20.8393 18.3126 20.8347 18.3815 20.8117C18.4505 20.7887 18.5122 20.7481 18.5606 20.6938C18.609 20.6396 18.6424 20.5736 18.6574 20.5025L21.9907 4.66876C22.0034 4.60848 22.0026 4.54614 21.9883 4.48623C21.974 4.42631 21.9466 4.37031 21.9081 4.32225C21.8695 4.27419 21.8208 4.23527 21.7655 4.2083C21.7101 4.18133 21.6494 4.16697 21.5878 4.16626V4.16668ZM21.0099 5.28251L17.9882 19.635L12.2091 14.5779L16.4466 10.725C16.5283 10.6507 16.5772 10.547 16.5825 10.4367C16.5878 10.3264 16.5491 10.2185 16.4749 10.1367C16.4006 10.0549 16.2969 10.006 16.1866 10.0007C16.0763 9.99542 15.9684 10.0341 15.8866 10.1083L11.3032 14.275C11.2598 14.3145 11.2252 14.3627 11.2016 14.4165C11.1781 14.4702 11.1662 14.5284 11.1667 14.5871C11.1673 14.6457 11.1802 14.7037 11.2046 14.757C11.2291 14.8104 11.2645 14.858 11.3086 14.8967L12.3074 15.77L10.0986 17.9783L8.64531 13.6183C8.62602 13.5609 8.59444 13.5084 8.55278 13.4644C8.51111 13.4205 8.46036 13.3861 8.40406 13.3638L5.17198 12.0704L21.0099 5.28251Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="mainmenu"
        show={show}
        onHide={handleClose}
        placement="top"
      >
        <Offcanvas.Header>
          <div className="menuheadleft">
            <span className="box"></span>
            <img
              src="\assets\offcanvaslogo.svg"
              alt="logoimg"
              className="logoimg"
            />
          </div>
          <div className="menuheadright">
            <button className="signupbtn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M4.5 13.5L13.5 4.5M13.5 4.5H6.75M13.5 4.5V11.25"
                  stroke="#001838"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Sign Up
            </button>
            <span className="box">
              <img
                src="\assets\close.svg"
                alt="closeimg"
                className="closeimg"
                onClick={handleClose}
              />
            </span>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="menucards">
            <HashLink onClick={handleClick1} to="#who" className="menuinnercard">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
              >
                <path
                  d="M30 19.375V32.5"
                  stroke="#3BD6FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M52.7005 21.4501V38.55C52.7005 41.35 51.2005 43.9501 48.7755 45.3751L33.9255 53.9501C31.5005 55.3501 28.5004 55.3501 26.0504 53.9501L11.2004 45.3751C8.77541 43.9751 7.27539 41.375 7.27539 38.55V21.4501C7.27539 18.6501 8.77541 16.05 11.2004 14.625L26.0504 6.05C28.4754 4.65 31.4755 4.65 33.9255 6.05L48.7755 14.625C51.2005 16.05 52.7005 18.6251 52.7005 21.4501Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30 40.5V40.75"
                  stroke="#3BD6FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="menucardpara">Who we are</p>
            </HashLink>
            <HashLink
              onClick={handleClick1}
              to="#features"
              className="menuinnercard"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
              >
                <path
                  d="M33.2499 20.275L36.5499 26.875C36.9999 27.775 38.1999 28.675 39.1999 28.825L45.1749 29.825C48.9999 30.475 49.8999 33.225 47.1499 35.975L42.4999 40.625C41.7249 41.4 41.2749 42.925 41.5249 44.025L42.85 49.8C43.9 54.35 41.4749 56.125 37.4499 53.75L31.8499 50.425C30.8249 49.825 29.1749 49.825 28.1499 50.425L22.5499 53.75C18.5249 56.125 16.0999 54.35 17.1499 49.8L18.475 44.025C18.725 42.95 18.2749 41.425 17.4999 40.625L12.85 35.975C10.1 33.225 10.9999 30.45 14.8249 29.825L20.7999 28.825C21.7999 28.65 22.9999 27.775 23.4499 26.875L26.7499 20.275C28.5249 16.7 31.4749 16.7 33.2499 20.275Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15 22.5V5"
                  stroke="#3BD6FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M45 22.5V5"
                  stroke="#3BD6FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30 10V5"
                  stroke="#3BD6FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="menucardpara">Platform Features</p>
            </HashLink>
            <HashLink
              onClick={handleClick2}
              to="#platform"
              className="menuinnercard"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="61"
                viewBox="0 0 60 61"
                fill="none"
              >
                <path
                  d="M38.9754 31.15C46.0585 31.15 51.8004 25.4081 51.8004 18.325C51.8004 11.242 46.0585 5.5 38.9754 5.5C31.8924 5.5 26.1504 11.242 26.1504 18.325C26.1504 25.4081 31.8924 31.15 38.9754 31.15Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M15.9002 49.1C20.1528 49.1 23.6002 45.6526 23.6002 41.4C23.6002 37.1474 20.1528 33.7 15.9002 33.7C11.6476 33.7 8.2002 37.1474 8.2002 41.4C8.2002 45.6526 11.6476 49.1 15.9002 49.1Z"
                  stroke="#3BD6FF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M41.5504 55.5C45.085 55.5 47.9504 52.6346 47.9504 49.1C47.9504 45.5654 45.085 42.7 41.5504 42.7C38.0158 42.7 35.1504 45.5654 35.1504 49.1C35.1504 52.6346 38.0158 55.5 41.5504 55.5Z"
                  stroke="#3BD6FF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
              </svg>
              <p className="menucardpara">Our Platform</p>
            </HashLink>
            <HashLink
              onClick={handleClick3}
              to="#blog"
              className="menuinnercard"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="61"
                viewBox="0 0 60 61"
                fill="none"
              >
                <path
                  d="M40 5.5H20C10 5.5 5 10.5 5 20.5V53C5 54.375 6.125 55.5 7.5 55.5H40C50 55.5 55 50.5 55 40.5V20.5C55 10.5 50 5.5 40 5.5Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M32.275 20.1001L19.3 33.0751C18.8 33.5751 18.325 34.5501 18.225 35.2501L17.525 40.2001C17.275 42.0001 18.525 43.2501 20.325 43.0001L25.2749 42.3001C25.9749 42.2001 26.95 41.7251 27.45 41.2251L40.425 28.2501C42.65 26.0251 43.725 23.4251 40.425 20.1251C37.125 16.8001 34.525 17.8501 32.275 20.1001Z"
                  stroke="#3BD6FF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30.4258 21.9501C31.5258 25.8751 34.6008 28.9751 38.5508 30.0751"
                  stroke="#3BD6FF"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="menucardpara">Our Blog</p>
            </HashLink>
          </div>
          <div className="menufooter">
            <div className="menufooterleft">
              <span className="box"></span>
              <p className="menufootpara">
                © incentiv 2024. All Rights Reserved
              </p>
            </div>
            <div className="menufooterright">
              <div className="socialicons">
                <a href="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1048_11456)">
                      <path
                        d="M-0.952047 0L6.64166 10.1535L-1 18.4087H0.719831L7.41009 11.1812L12.8156 18.4087H18.6683L10.6473 7.68411L17.7601 0H16.0403L9.8789 6.65646L4.90061 0H-0.952047ZM1.57709 1.26683H4.26581L16.1388 17.1417H13.45L1.57709 1.26683Z"
                        fill="#3BD6FF"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1048_11456">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a href="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M15.627 2.75276C13.6477 2.62268 10.4273 2.49963 9 2.49963C7.57266 2.49963 4.34883 2.62268 2.37305 2.75276C1.16016 2.8301 0.210938 3.84612 0.210938 5.05901V12.941C0.210938 14.1539 1.16016 15.17 2.37305 15.2473C4.35234 15.3774 7.57266 15.5004 9 15.5004C10.4273 15.5004 13.6512 15.3774 15.627 15.2473C16.8398 15.1664 17.7891 14.1539 17.7891 12.941V5.05901C17.7891 3.84612 16.8398 2.8301 15.627 2.75276ZM17.0859 12.941C17.0859 13.7848 16.425 14.4914 15.5813 14.5477C13.616 14.6778 10.4168 14.7973 9 14.7973C7.5832 14.7973 4.38398 14.6742 2.41875 14.5477C1.575 14.4914 0.914062 13.7883 0.914062 12.941V5.05901C0.914062 4.21526 1.575 3.50862 2.41875 3.45237C4.38398 3.32229 7.5832 3.20276 9 3.20276C10.4168 3.20276 13.616 3.32581 15.5813 3.45237C16.425 3.50862 17.0859 4.21174 17.0859 5.05901V12.941Z"
                      fill="white"
                    />
                    <path
                      d="M12.2102 8.69414L7.63281 6.05039C7.52383 5.98711 7.39023 5.98711 7.28125 6.05039C7.17227 6.11367 7.10547 6.22969 7.10547 6.35625V11.6438C7.10547 11.7703 7.17227 11.8863 7.28125 11.9496C7.33398 11.9813 7.39727 11.9953 7.45703 11.9953C7.5168 11.9953 7.58008 11.9813 7.63281 11.9496L12.2102 9.30586C12.3191 9.24258 12.3859 9.12656 12.3859 9C12.3859 8.87344 12.3191 8.75742 12.2102 8.69414ZM7.80859 11.0355V6.96445L11.3312 9L7.80859 11.0355Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a href="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.34375 4.78125C3.01528 4.78125 1.125 6.67181 1.125 9C1.125 11.3285 3.01528 13.2188 5.34375 13.2188C7.67222 13.2188 9.5625 11.3285 9.5625 9C9.5625 6.67181 7.67222 4.78125 5.34375 4.78125ZM5.34375 5.34375C7.36172 5.34375 9 6.98203 9 9C9 11.0182 7.36172 12.6562 5.34375 12.6562C3.32578 12.6562 1.6875 11.0182 1.6875 9C1.6875 6.98203 3.32578 5.34375 5.34375 5.34375ZM12.375 5.0625C11.8595 5.0625 11.3726 5.35669 10.9859 5.88319C10.4693 6.58687 10.1253 7.72003 10.1253 9.00028C10.1253 10.28 10.4693 11.4134 10.9859 12.1171C11.3726 12.6436 11.8595 12.9375 12.3753 12.9375C12.8908 12.9375 13.3777 12.6433 13.7641 12.1168C14.281 11.4131 14.625 10.2803 14.625 9C14.625 7.71975 14.281 6.58659 13.7644 5.88291C13.3774 5.35669 12.8905 5.0625 12.375 5.0625ZM12.375 5.625C12.7288 5.625 13.0455 5.85506 13.3107 6.21619C13.7717 6.84366 14.0625 7.85869 14.0625 9.00028C14.0625 10.1416 13.7717 11.1566 13.3107 11.7841C13.0455 12.1452 12.7285 12.375 12.375 12.375C12.0215 12.375 11.7045 12.1452 11.4393 11.7841C10.9786 11.1566 10.6875 10.1416 10.6875 9C10.6875 7.85812 10.9786 6.84337 11.4393 6.21591C11.7045 5.85478 12.0215 5.625 12.375 5.625ZM16.0312 5.625C15.9441 5.625 15.856 5.65313 15.7725 5.72063C15.6831 5.79263 15.5849 5.93831 15.5076 6.15881C15.3208 6.6915 15.1875 7.76419 15.1875 9.00028C15.1875 10.2361 15.3208 11.3088 15.5076 11.8415C15.5849 12.062 15.6831 12.2077 15.7725 12.2797C15.856 12.3472 15.9441 12.3753 16.0312 12.3753C16.1184 12.3753 16.2067 12.3472 16.29 12.2797C16.3797 12.2077 16.4779 12.062 16.5552 11.8415C16.742 11.3088 16.875 10.2361 16.875 9C16.875 7.76391 16.742 6.69122 16.5552 6.15853C16.4779 5.93803 16.3797 5.79234 16.2903 5.72034C16.2178 5.65915 16.1261 5.6254 16.0312 5.625ZM16.0312 6.36581C16.202 6.87009 16.3125 7.8615 16.3125 9.00028C16.3125 10.1388 16.202 11.1302 16.0312 11.6345C15.8608 11.1302 15.75 10.1388 15.75 9.00028C15.75 7.8615 15.8608 6.87009 16.0312 6.36581Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a href="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1048_11465)">
                      <path
                        d="M12.765 1.125H5.235C2.96511 1.125 1.125 2.96511 1.125 5.235V12.765C1.125 15.0349 2.96511 16.875 5.235 16.875H12.765C15.0349 16.875 16.875 15.0349 16.875 12.765V5.235C16.875 2.96511 15.0349 1.125 12.765 1.125Z"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 13.125C11.2782 13.125 13.125 11.2782 13.125 9C13.125 6.72183 11.2782 4.875 9 4.875C6.72183 4.875 4.875 6.72183 4.875 9C4.875 11.2782 6.72183 13.125 9 13.125Z"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.5 4.125C13.7071 4.125 13.875 3.95711 13.875 3.75C13.875 3.54289 13.7071 3.375 13.5 3.375C13.2929 3.375 13.125 3.54289 13.125 3.75C13.125 3.95711 13.2929 4.125 13.5 4.125Z"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1048_11465">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a href="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1048_11470)">
                      <path
                        d="M4.25957 5.88001H0.50957C0.38957 5.88001 0.26957 5.97001 0.26957 6.12001V17.34C0.26957 17.46 0.35957 17.58 0.50957 17.58H4.22957C4.34957 17.58 4.46957 17.49 4.46957 17.34V6.09001C4.46957 5.97001 4.37957 5.88001 4.25957 5.88001ZM4.01957 17.1H0.74957V6.33001H4.01957V17.1ZM2.39957 0.450012C1.04957 0.450012 0.0595703 1.35001 0.0595703 2.61001C0.0595703 3.87001 1.01957 4.77001 2.33957 4.77001H2.39957C3.74957 4.77001 4.73957 3.87001 4.73957 2.61001C4.67957 1.35001 3.74957 0.450012 2.39957 0.450012ZM2.39957 4.35001C1.28957 4.35001 0.53957 3.63001 0.53957 2.64001C0.53957 1.62001 1.28957 0.900012 2.39957 0.900012C3.47957 0.900012 4.22957 1.59001 4.25957 2.61001C4.25957 3.63001 3.50957 4.35001 2.39957 4.35001ZM13.4096 5.61001C11.7896 5.61001 10.8596 6.30001 10.2596 6.99001V6.09001C10.2596 5.97001 10.1696 5.85001 10.0196 5.85001H6.29957C6.23957 5.85001 6.17957 5.88001 6.14957 5.91001C6.11957 5.94001 6.08957 6.00001 6.08957 6.09001C6.14957 7.11001 6.08957 17.19 6.08957 17.31C6.08957 17.37 6.11957 17.43 6.14957 17.46C6.17957 17.49 6.23957 17.52 6.29957 17.52H10.0196C10.1396 17.52 10.2596 17.43 10.2596 17.28V11.04C10.2596 10.65 10.2896 10.38 10.3796 10.2C10.5296 9.84002 10.9796 8.97001 12.0896 8.97001C13.1996 8.97001 13.7396 9.75002 13.7396 11.28V17.28C13.7396 17.4 13.8296 17.52 13.9796 17.52H17.6996C17.8196 17.52 17.9396 17.43 17.9396 17.28V10.89C17.9396 7.59001 16.2296 5.61001 13.4096 5.61001ZM17.4596 17.1H14.1896V11.31C14.1896 9.54002 13.4396 8.55001 12.0896 8.55001C11.1296 8.55001 10.3496 9.12002 9.95957 10.05C9.86957 10.29 9.80957 10.59 9.80957 11.04V17.1H6.53957C6.53957 15.66 6.56957 8.01002 6.53957 6.33001H9.80957V7.62001C9.77957 7.68001 9.77957 7.77001 9.83957 7.83001C9.86957 7.89001 9.95957 7.95001 10.0496 7.95001C10.1696 7.95001 10.2596 7.86001 10.2596 7.77001C10.7996 6.96001 11.6396 6.06001 13.4096 6.06001C15.9896 6.06001 17.4896 7.83001 17.4896 10.89V17.1H17.4596Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1048_11470">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a href="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M15.3041 2.99999C15.2623 2.99942 15.2208 3.00759 15.1823 3.02399L2.58228 8.424C2.52772 8.44737 2.48131 8.4864 2.44894 8.53615C2.41656 8.58591 2.39968 8.64415 2.40041 8.7035C2.40115 8.76286 2.41948 8.82066 2.45309 8.86959C2.48669 8.91853 2.53405 8.95639 2.58918 8.9784L5.45988 10.1268L6.61578 13.5951C6.63198 13.6436 6.66031 13.6872 6.69807 13.7216C6.73584 13.7561 6.78179 13.7804 6.83157 13.7921C6.88134 13.8038 6.93329 13.8026 6.98247 13.7886C7.03165 13.7746 7.07644 13.7483 7.11258 13.7121L9.07458 11.7504L12.7031 14.9256C12.7425 14.9601 12.7902 14.9836 12.8415 14.9939C12.8928 15.0043 12.9459 15.001 12.9956 14.9844C13.0452 14.9679 13.0897 14.9386 13.1245 14.8996C13.1594 14.8605 13.1834 14.813 13.1942 14.7618L15.5942 3.36149C15.6033 3.3181 15.6027 3.27321 15.5924 3.23007C15.5821 3.18693 15.5624 3.14661 15.5347 3.11201C15.5069 3.07741 15.4719 3.04939 15.432 3.02997C15.3921 3.01054 15.3484 3.00021 15.3041 2.99969V2.99999ZM14.888 3.8034L12.7124 14.1372L8.55138 10.4961L11.6024 7.722C11.6612 7.66851 11.6964 7.59385 11.7003 7.51442C11.7041 7.43499 11.6762 7.35729 11.6228 7.2984C11.5693 7.23955 11.4946 7.20434 11.4152 7.20052C11.3358 7.19669 11.2581 7.22456 11.1992 7.278L7.89918 10.278C7.8679 10.3064 7.84298 10.3411 7.82604 10.3798C7.80911 10.4186 7.80054 10.4604 7.80091 10.5027C7.80128 10.5449 7.81057 10.5866 7.82818 10.625C7.84579 10.6635 7.87131 10.6977 7.90308 10.7256L8.62218 11.3544L7.03188 12.9444L5.98548 9.80519C5.97159 9.76385 5.94886 9.72604 5.91886 9.69438C5.88886 9.66272 5.85232 9.63799 5.81178 9.6219L3.48468 8.6907L14.888 3.8034Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
              <span className="box"></span>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
